<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th></th>
          <th class="text-center">Date</th>
          <th class="text-center" v-if="typeId == 1">Dureté (°F)</th>
          <th class="text-center">Index du compteur (m3)</th>
          <th class="text-center">Consommation d'eau (m3)</th>
          <th class="text-center" v-if="typeId != 1">Consommation réelle de produit (cm3/m3)</th>
          <th class="text-center" v-text="quantityDelivered"></th>
          <th class="text-center" v-if="typeId == 1">Quantité insérée (kg)</th>
          <th class="text-center" v-text="remainingQuantity"></th>
          <th class="text-left" style="min-width: 250px !important">Observations</th>
          <th class="text-left" style="min-width: 250px !important">Pièces remplacées</th>
          <th class="text-left">Médias</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(intervention, index) in interventions">
          <tr :key="intervention.id">
            <td class="text-center">
              <v-btn
                color="red"
                icon
                v-if="!intervention.synchronized"
                @click="removeIntervention(intervention.id)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn
                color="blue"
                icon
                v-if="!intervention.synchronized"
                :to="{
                  name: 'intervention_update',
                  params: {
                    typeId: typeId,
                    sheetId: sheetId,
                    interventionId: intervention.id,
                    sheetsId: sheetsId,
                  },
                }"
              >
                <v-icon>mdi-pencil-box-outline</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              {{ intervention.intervention_date }}
            </td>
            <td class="text-center" v-if="typeId == 1">
              {{ intervention.hardness }}
            </td>
            <td class="text-center">{{ intervention.meter_index }}</td>
            <td class="text-center">
              <template v-if="intervention.synchronized">
                {{ intervention.consumption }}
              </template>
              <template v-else>
                {{ getConsumption(intervention, index) }}
              </template>
            </td>
            <td class="text-center" v-if="typeId != 1">
              <template v-if="intervention.synchronized">
                {{ intervention.real_product_consumption }}
              </template>
              <template v-else>
                {{ getRealProductConsumption(intervention, index) }}
              </template>
            </td>
            <td class="text-center">{{ intervention.delivered_quantity }}</td>
            <td class="text-center" v-if="typeId == 1">
              {{ intervention.inserted_quantity }}
            </td>
            <td class="text-center">{{ intervention.remaining_quantity }}</td>
            <td>{{ intervention.observation }}</td>
            <td>{{ intervention.replaced_parts }}</td>
            <td>
              <v-btn
                color="primary"
                v-if="
                  intervention.images.length !=
                  intervention.images.filter((res) => res.toDelete == true)
                    .length
                "
                :to="{
                  name: 'intervention_images',
                  params: {
                    interventionId: intervention.id,
                    sheetsId: sheetsId,
                  },
                }"
                small
                depressed
              >
                Images
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapActions } from 'pinia'
import { useInterventionsStore } from '@/store/modules/intervention'

export default {
  props: [
    'interventions',
    'typeId',
    'sheetsId',
    'sheetId',
  ],
  computed: {
    quantityDelivered() {
      if (this.typeId == 2) {
        return 'Quantité livrée (L)'
      }

      return 'Quantité livrée (kg)'
    },
    remainingQuantity() {
      if (this.typeId == 1) {
        return 'Nombre de sac'
      } else if (this.typeId == 2) {
        return 'Quantité restante (L)'
      }

      return 'Quantité restante (kg)';
    }
  },
  methods: {
    ...mapActions(useInterventionsStore, [
      'removeIntervention',
    ]),
    getConsumption(current, currentIndex) {
      if (!current.synchronized) {
        let value = this.interventions.findLast((intervention, index) => index < currentIndex)
        if (value) {
          return current.meter_index - value.meter_index
        }
      }
    },
    getRealProductConsumption(current, currentIndex) {
      if (!current.synchronized) {
        let consumption = this.getConsumption(current, currentIndex)
        let value = this.interventions.findLast((intervention, index) => index < currentIndex)
        if (value && consumption) {
          let total = (value.remaining_quantity + parseInt(current.delivered_quantity) - parseInt(current.remaining_quantity)) * 1000 / consumption
          return Math.round(total)
        }
      }
    }
  }
}

</script>
